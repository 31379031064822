/* eslint-disable react/forbid-prop-types */
// PerfiladorInput.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Input from '../../../../components/Input';

export const PerfiladorInput = ({
  classes,
  inputData,
  isEmpty,
  name,
  onChange,
  titleLabel,
  validations,
  value,
}) => (
  <Box className={classes.inputContainer}>
    <Typography className={classes.title}>{titleLabel}</Typography>
    <Input
      className={classes.input}
      disabled={inputData.disabled}
      hideLabel={inputData?.hideLabel}
      isEmpty={isEmpty}
      label={inputData.label}
      name={name}
      onChange={onChange}
      placeHolder={inputData.placeHolder}
      required={inputData.required}
      type={inputData.type}
      validations={validations}
      value={value}
    />
  </Box>
);

PerfiladorInput.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titleLabel: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.string.isRequired,
};

PerfiladorInput.defaultProps = {
  isEmpty: false,
  name: '',
  titleLabel: '',
  validations: [],
};
