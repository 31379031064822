/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { scroller } from 'react-scroll';

import * as googleAnalytics from '../../utils/googleAnalytics/events';
import { LandingLayout } from './layouts/LandingLayout';
import { HeaderBar } from './components/HeaderBar';
import {
  Header,
} from './sections';
import { Loader, WhatsappFloat } from '../../components';
import useLoading from '../../hooks/useLoading';
import { CalCredit } from './sections/CalcCredit';
import { InfoStepsComponent } from './sections/InfoStepsComponent';
import { FooterInfoImages } from './sections/FooterInfoImages';
import { WallOfLoveSection } from './sections/WallOfLoveSection';
import { BanksCarrousel } from './sections/BanksCarrousel';
import { EntitiesSection } from './sections/EntitiesSection';
import { LandingContext } from '../../context/landing';

const Landing = () => {
  const { isLoading, startLoading, endLoading } = useLoading();
  const location = useLocation();
  const { landingData } = useContext(LandingContext);
  const { main: { logoClickRoute = '' } = {} } = landingData;

  const [agentId, setAgentId] = useState(null);

  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  const handleLoad = () => {
    const queryParams = new URLSearchParams(location.search);
    const agentIdParam = queryParams.get('agent');
    const currentURL = window.location.href;

    if (agentIdParam) {
      setAgentId(agentIdParam);
      queryParams.delete('agent');
      const id = location.hash.substring(1);

      if (id) {
        scroller.scrollTo(id, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        const newURL = currentURL.replace(/(\?|&)agent=[^&]*/, '');
        window.history.replaceState({}, document.title, `${newURL}#${id}`);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <LandingLayout>
      <Loader open={isLoading} />
      <HeaderBar
        customLogoClickRoute={logoClickRoute}
        navigateOnLogoClick
      />
      <Header />
      <InfoStepsComponent />
      <CalCredit
        startLoading={startLoading}
        endLoading={endLoading}
        agentId={agentId}
        buttonTitle="Consigue tu oferta Ahora"
      />
      <WallOfLoveSection />
      <BanksCarrousel />
      <EntitiesSection />
      <FooterInfoImages />
      <WhatsappFloat />
    </LandingLayout>
  );
};

export default Landing;
